import React from 'react';

interface ProfilePictureProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    name: string;
    description: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ name, description, ...props }) => (
    <>
        <img
            alt="Profile Picture"
            className="rounded-full w-48 h-48 mx-auto mb-2 border-4 border-light-blue"
            {...props}
        />
        <h1 className="text-center text-4xl font-extrabold mb-2">
            <span className="block xl:inline">{name}</span>
        </h1>
        <h4 className="text-center text-1xl text-gray-500 dark:text-gray-200 font-medium">
            <span className="block xl:inline m-2">{description}</span>
            {'|'}
            <a className="no-underline text-blue-500 m-2" target="_blank" href={`/doc/CV_2023.pdf`}>
                CV.pdf
            </a>
        </h4>
    </>
);

export default ProfilePicture;
