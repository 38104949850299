import { createContext } from 'react';

export interface IThemeContext {
    theme: ThemeKind;
    switchTheme: () => void;
}

export enum ThemeKind {
    Dark = 'dark',
    Light = 'light',
}

const contextStub = {
    theme: ThemeKind.Light,
    switchTheme: () => {
        // stub
    },
};

const ThemeContext = createContext<IThemeContext>(contextStub);

export default ThemeContext;
