import React from 'react';
import useTheme from '../context/hooks/useTheme';
import { ThemeKind } from '../context/ThemeContext';
import DarkIcon from '../icons/Dark';
import LightIcon from '../icons/Light';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ThemeSelectionProps {}

const ThemeSelection: React.FC<ThemeSelectionProps> = () => {
    const { theme, switchTheme } = useTheme();

    return (
        <div className="z-10 absolute left-4 top-4">
            <button
                onClick={switchTheme}
                className="w-12 h-12 rounded-xl flex justify-center items-center bg-[rgba(0,0,0,0.2)] text-black dark:text-white"
            >
                {theme === ThemeKind.Dark ? <LightIcon /> : <DarkIcon />}
            </button>
        </div>
    );
};

export default ThemeSelection;
