import React from 'react';

import info from '../info.json';
import EmailIcon from '../icons/Email';
import GithubIcon from '../icons/Github';
import GitlabIcon from '../icons/Gitlab';
import LinkedinIcon from '../icons/Linkedin';
import LocationIcon from '../icons/Location';
import TwitterIcon from '../icons/Twitter';
import ProfilePicture from '../components/ProfilePicture';

import MarkdownLayout from '../components/MarkdownLayout';
import EducationMarkdown from '../markdown/education.md';
import ProjectsMarkdown from '../markdown/projects.md';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LandingProps {}

const Landing: React.FC<LandingProps> = () => {
    return (
        <div className="relative flex flex-col 2xl:flex-row gap-y-10 justify-evenly items-center p-10">
            <div className="relative sm:max-w-xl ">
                <div className="absolute inset-0 bg-gradient-to-r from-gray-400 to-gray-200 shadow-lg transform -skew-y-3 sm:skew-y-0 -rotate-3 rounded-3xl"></div>
                <div className="relative bg-white shadow-lg rounded-3xl p-2 sm:p-10 dark:bg-gray-800 border z-10">
                    <div className="sm:max-w-xl sm:mx-auto text-black dark:text-white">
                        <ProfilePicture
                            src="/img/profile.jpg"
                            name={info.PROFILE_NAME}
                            description={info.PROFILE_DESCRIPTION}
                        />
                        <div className="flex flex-col justify-center">
                            <div className="py-8 text-base leading-6 text-gray-700 dark:text-gray-300 sm:text-lg">
                                <p className="text-justify p-2">
                                    I am a software engineer with broad experience in full-stack development and DevOps.
                                    <div className="p-2" />
                                    Currently, most of my work revolves around blockchain technologies, especially on
                                    Substrate and Tezos blockchains.
                                </p>
                            </div>
                            <div className="border-4 border-light-blue rounded-xl p-2">
                                <div className="mt-2 flex items-center place-content-between text-sm text-gray-500 space-x-10">
                                    <EmailIcon width={24} height={24} className="text-black dark:text-white" />
                                    <a
                                        className="no-underline text-blue-500 font-bold"
                                        target="_blank"
                                        href={`mailto:${info.E_MAIL}`}
                                    >
                                        {info.E_MAIL}
                                    </a>
                                </div>
                                <div className="mt-2 flex items-center place-content-between text-sm text-gray-500 space-x-10">
                                    <LinkedinIcon width={24} height={24} className="text-black dark:text-white" />
                                    <a
                                        className="no-underline text-blue-500 font-bold"
                                        target="_blank"
                                        href={`https://www.linkedin.com/${info.LINKEDIN}`}
                                    >
                                        {info.LINKEDIN}
                                    </a>
                                </div>
                                <div className="mt-2 flex items-center place-content-between text-sm text-gray-500 space-x-10">
                                    <TwitterIcon width={24} height={24} className="text-black dark:text-white" />
                                    <a
                                        className="no-underline text-blue-500 font-bold"
                                        target="_blank"
                                        href={`https://twitter.com/${info.TWITTER}`}
                                    >
                                        @{info.TWITTER}
                                    </a>
                                </div>
                                <div className="mt-2 flex items-center place-content-between text-sm text-gray-500 space-x-10">
                                    <GithubIcon width={24} height={24} className="text-black dark:text-white" />
                                    <a
                                        className="no-underline text-blue-500 font-bold"
                                        target="_blank"
                                        href={`https://github.com/${info.GIT}`}
                                    >
                                        github.com/{info.GIT}
                                    </a>
                                </div>
                                <div className="mt-2 flex items-center place-content-between text-sm text-gray-500 space-x-10">
                                    <GitlabIcon width={24} height={24} className="text-black dark:text-white" />
                                    <a
                                        className="no-underline text-blue-500 font-bold"
                                        target="_blank"
                                        href={`https://gitlab.com/${info.GIT}`}
                                    >
                                        gitlab.com/{info.GIT}
                                    </a>
                                </div>
                                <div className="mt-2 flex items-center place-content-between text-sm text-gray-500 space-x-10">
                                    <LocationIcon width={24} height={24} className="text-black dark:text-white" />
                                    <span className="align-right font-bold text-black dark:text-white">
                                        {info.LOCATION}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="p-5 p-5 shadow-lg rounded-lg border ">
                    <MarkdownLayout markdown={EducationMarkdown} />
                </div>
                <div className="mt-2 p-5 p-5 shadow-lg rounded-lg border">
                    <MarkdownLayout markdown={ProjectsMarkdown} />
                </div>
            </div>
        </div>
    );
};

export default Landing;
