import * as React from 'react';
import { render } from 'react-dom';

import ThemeProvider from './context/ThemeProvider';
import ThemeSelection from './components/ThemeSelection';
import Landing from './pages/Landing';

render(
    <React.StrictMode>
        <ThemeProvider>
            <div className="min-h-screen h-full bg-gray-100 dark:bg-gray-800">
                <Landing />
                <ThemeSelection />
            </div>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
