import React from 'react';

type DarkIconProps = Exclude<React.SVGProps<SVGSVGElement>, 'viewBox'>;

const DarkIcon: React.FC<DarkIconProps> = ({ width = 24, height = 24, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        {...props}
        strokeWidth={2}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
    </svg>
);

export default DarkIcon;
