import { useContext } from 'react';
import ThemeContext, { IThemeContext } from '../ThemeContext';

const useTheme = (): IThemeContext => {
    const context = useContext(ThemeContext);
    if (context == null) {
        throw new Error('`theme context` is not available.');
    }
    return context;
};

export default useTheme;
