import React from 'react';
import ThemeContext, { ThemeKind } from './ThemeContext';

const THEME_STORAGE_KEY = 'THEME';

const getThemeFromStorage = (): ThemeKind => {
    const theme = window.localStorage.getItem(THEME_STORAGE_KEY) as ThemeKind | null;
    if (theme && Object.values(ThemeKind).includes(theme)) {
        return theme as ThemeKind;
    }
    return ThemeKind.Light;
};

const saveTheme = (theme: ThemeKind) => {
    window.localStorage.setItem(THEME_STORAGE_KEY, theme);
};

const ThemeProvider: React.FC<{ children: React.ReactNode }> = (props) => {
    const [theme, setTheme] = React.useState<ThemeKind>(() => getThemeFromStorage());

    const switchTheme = () => {
        setTheme((oldTheme) => {
            const newTheme = oldTheme === ThemeKind.Dark ? ThemeKind.Light : ThemeKind.Dark;
            saveTheme(newTheme);
            return newTheme;
        });
    };

    // Load theme
    React.useEffect(() => {
        // On page load or when changing themes, best to add inline in `head` to avoid FOUC
        if (theme === ThemeKind.Dark /* || window.matchMedia('(prefers-color-scheme: dark)').matches */) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [theme]);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                switchTheme,
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
